import Footer from "../Footer";
const Cancellation = () => {
  return (
    <div>
      <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
        <h1 className="text-sm font-bold md:text-lg text-white ml-5">
          Shri Vishwa Vyakhyan Mala 2024
        </h1>
      </nav>
      <section className="p-4">
        <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
          <h2 className="font-bold text-xl mb-2">
            Three days National Seminar On Ayurveda
          </h2>

          <div id="tc" className={`grid grid-cols-1  gap-4`}>
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
            <h2 className="font-bold text-xl mb-2">
            Terms and Conditions
          </h2>
              <p>
                For the purpose of these Terms and Conditions, The term "we",
                "us", "our" used anywhere on this page shall mean SHRI SADGURU
                VISHWANATH MAHARAJ RUKADIKAR TRUST, whose registered/operational
                office is श्री सदगुरू विश्वनाथ महाराज रुकडीकर ट्रस्ट मुख्य
                कार्यालय विश्वपंढरी ६९७ / ब, सदगुरू विश्वनाथ महाराज रुकडीकर
                मार्ग, आय. टी. पार्क समोर, कोल्हापूर, महाराष्ट्र, भारत ४१६०१२
                Kolhapur MAHARASHTRA 416012 . "you", “your”, "user", “visitor”
                shall mean any natural or legal person who is visiting our
                website and/or agreed to purchase from us. Your use of the
                website and/or purchase from us are governed by following Terms
                and Conditions:
              </p>
              <p>
              <ul>
                <li>
                  The content of the pages of this website is subject to change
                  without notice.
                </li>
                <li>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  found or offered on this website for any particular purpose.
                  You acknowledge that such information and materials may
                  contain inaccuracies or errors and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </li>
                <li>
                  Your use of any information or materials on our website and/or
                  product pages is entirely at your own risk, for which we shall
                  not be liable. It shall be your own responsibility to ensure
                  that any products, services or information available through
                  our website and/or product pages meet your specific
                  requirements.
                </li>
                <li>
                  Our website contains material which is owned by or licensed to
                  us. This material includes, but are not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </li>
                <li>
                  All trademarks reproduced in our website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website.
                </li>
                <li>
                  Unauthorized use of information provided by us shall give rise
                  to a claim for damages and/or be a criminal offense.
                </li>
                <li>
                  From time to time our website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information.
                </li>
                <li>
                  You may not create a link to our website from another website
                  or document without SHRI SADGURU VISHWANATH MAHARAJ RUKADIKAR
                  TRUST’s prior written consent.
                </li>
                <li>
                  Any dispute arising out of use of our website and/or purchase
                  with us and/or any engagement with us is subject to the laws
                  of India .
                </li>
                <li>
                  We, shall be under no liability whatsoever in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any Transaction, on Account of
                  the Cardholder having exceeded the preset limit mutually
                  agreed by us with our acquiring bank from time to time
                </li>
              </ul>
              </p>
            </div>
            <div id="pp" className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
                Privacy Policy: (15 May 2024)
              </h2>
              <p>
                This privacy policy sets out how SHRI SADGURU VISHWANATH MAHARAJ
                RUKADIKAR TRUST uses and protects any information that you give
                SHRI SADGURU VISHWANATH MAHARAJ RUKADIKAR TRUST when you visit
                their website and/or agree to purchase from them.
              </p>
              <p>
                SHRI SADGURU VISHWANATH MAHARAJ RUKADIKAR TRUST is committed to
                ensuring that your privacy is protected. Should we ask you to
                provide certain information by which you can be identified when
                using this website, and then you can be assured that it will
                only be used in accordance with this privacy statement.
              </p>
              <p>
                SHRI SADGURU VISHWANATH MAHARAJ RUKADIKAR TRUST may change this
                policy from time to time by updating this page. You should check
                this page from time to time to ensure that you adhere to these
                changes.
              </p>
              <p>We may collect the following information:</p>
              <ul>
                <li>Name</li>
                <li>Contact information including email address</li>
                <li>
                  Demographic information such as postcode, preferences and
                  interests, if required
                </li>
                <li>
                  Other information relevant to customer surveys and/or offers
                </li>
              </ul>
              <p>What we do with the information we gather:</p>
              <p>
                We require this information to understand your needs and provide
                you with a better service, and in particular for the following
                reasons:
              </p>
              <ul>
                <li>Internal record keeping.</li>
                <li>
                  We may use the information to improve our products and
                  services.
                </li>
              </ul>
              <p>
                We try to ensuring that your information is secure. In order to
                prevent unauthorised access or disclosure we have put in
                suitable measures. If you believe that any information we are
                holding on you is incorrect or incomplete, please write to श्री
                सदगुरू विश्वनाथ महाराज रुकडीकर ट्रस्ट मुख्य कार्यालय विश्वपंढरी
                ६९७ / ब, सदगुरू विश्वनाथ महाराज रुकडीकर मार्ग, आय. टी. पार्क
                समोर, कोल्हापूर, महाराष्ट्र, भारत ४१६०१२ Kolhapur MAHARASHTRA
                416012 . or contact us at or as soon as possible. We will
                promptly correct any information found to be incorrect.
              </p>
            </div>
            <div id="cp" className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
                Cancellation & Refund Policy
              </h2>
              <p>No cancellations & Refunds are entertained</p>
            </div>
            <div id="sh" className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
              Shipping & Delivery Policy
              </h2>
              <p>Shipping is not applicable for business.</p>
            </div>
          </div>
        </div>
      </section>

      <div></div>
      <Footer />
    </div>
  );
};

export default Cancellation;
