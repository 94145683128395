import React from 'react';

const Footer = () => { 
  const handleNavigation = (path) => {
    window.location = path;
  };
  return (
  <footer className="bg-gray-800 text-white py-8 px-4 mt-8">
    <div className="container mx-auto">
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <h3 className="text-xl mb-3">
            Shri Sadguru Vishwanath Maharaj Rukdikar Trust
          </h3>
          <p className="text-sm">
            Head Office Vishwapandhari 697 / B, Sadguru Vishwanath
            Maharaj Rukdikar Marg, Opposite I.T Park, Kolhapur,
            Maharashtra, India 416012
          </p>
        </div>
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
        <h3 className="text-xl mb-3">Policies</h3>
          <ul className="text-sm">
          <li><a href='https://merchant.razorpay.com/policy/OAiEYTgpL1MQcx/privacy'>Privacy Policy</a></li>
      <li><a href='https://merchant.razorpay.com/policy/OAiEYTgpL1MQcx/terms'>Terms and Conditions</a></li>
      <li><a href='https://merchant.razorpay.com/policy/OAiEYTgpL1MQcx/refund'>Cancellation Policy</a></li>
      <li><a href='https://merchant.razorpay.com/policy/OAiEYTgpL1MQcx/shipping'>Shipping & Delivery Policy</a></li>

          </ul>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl mb-3">Contact Us</h3>
          <p className="text-sm">Name: Mr. Navin Patil</p>
          <p className="text-sm">Phone: +91 9730817505</p>
        </div>
      </div>
    </div>
  </footer>
);}

export default Footer;