// import { useState, useEffect } from "react";
// import axios from "axios";
// import ClipLoader from "react-spinners/ClipLoader";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import Footer from "../Footer";

// const AdminDashboard = () => {
//   // Extract the payment ID and order ID from the query parameters
//   const [alreadyMarked, setAlreadyMarked] = useState(false);
//   const [data, setData] = useState({
//     paymentPageId: "",
//     paymentPageTitle: "",
//     paymentDate: "",
//     orderId: "",
//     itemName: "",
//     itemAmount: 0,
//     itemQuantity: 0,
//     itemPaymentAmount: 0,
//     totalPaymentAmount: 0,
//     currency: "",
//     paymentStatus: "",
//     paymentId: "",
//     fullName: "",
//     email: "",
//     whatsappMobileNumber: "",
//     clinicOrCollegeAddress: "",
//     state: "",
//     district: "",
//     city: "",
//     pincode: "",
//     gender: "",
//     residence: "",
//     isMark: false,
//   });
//   const [isLoading, setIsLoading] = useState(false);

//   const getColor = () => {
//     return alreadyMarked ? "bg-red-500" : "bg-custom";
//   };

//   const location = useLocation();

// useEffect(() => {
//   const fetchUserDetails = async () => {
//     try {
//       const params = new URLSearchParams(location.search);
//       const paymentId = params.get("paymentId");
//       const orderId = params.get("orderId");

//       if (paymentId && orderId) {
//         const token = localStorage.getItem("admintoken");
//         const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/getUser`;
//         setIsLoading(true);
//         const response = await axios.post(
//           url,
//           { OrderId: orderId, PaymentId: paymentId },
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.status === 200 && response.data) {
//           setData((prevState) => ({ ...prevState, ...response.data.user }));
//           setAlreadyMarked(response.data.isMark);
//         }
//       }
//     } catch (error) {
//       if (error.response && error.response.status >= 400 && error.response.status <= 500) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went wrong");
//       }
//     }

//     setIsLoading(false);
//   };

//   fetchUserDetails();
// }, [location]);

//   const handleLogout = async () => {
//     setIsLoading(true);

//     const token = localStorage.getItem("admintoken");
//     const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/logout`;

//     try {
//       const response = await axios.post(url, {}, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.status === 200) {
//         localStorage.removeItem("admintoken");
//         window.location = "/login";
//       } else {
//         toast.error(response.data.error);
//       }
//     } catch (error) {
//       if (error.response && error.response.status >= 400 && error.response.status <= 500) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went wrong");
//       }
//     }

//     setIsLoading(false);
//   };

//   const handleChange = (e) => {
//     setData({ ...data, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async () => {
//     // Add logic for form submission if required
//   };

//   return (
//     <div>
//       {isLoading ? (
//         <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
//           <ClipLoader color={"#123abc"} loading={isLoading} size={150} />
//         </div>
//       ) : (
//         <div className="bg-gray-100">
//           <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
//             <h1 className="text-sm font-bold md:text-lg text-white ml-5">
//               Payment Dashboard
//             </h1>
//             <button
//               className="border-none outline-none py-2 md:py-3 bg-white rounded-full w-24 md:w-28 font-bold text-xs md:text-sm cursor-pointer mr-5"
//               onClick={handleLogout}
//             >
//               Logout
//             </button>
//           </nav>
//           <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center p-8">
//             <div className="w-11/12 md:max-w-4xl mx-auto shadow-md overflow-hidden md:flex rounded-2xl">
//               <div className="w-full p-8 bg-white flex flex-col items-center justify-center md:w-1/2">
//                 <form onSubmit={handleSubmit}>
//                   <h1 className="mb-5 text-3xl">Payment Details</h1>
// <input
//   type="text"
//   name="fullName"
//   onChange={handleChange}
//   placeholder="Full Name"
//   value={data.fullName}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="email"
//   name="email"
//   value={data.email}
//   onChange={handleChange}
//   placeholder="Email"
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="tel"
//   name="whatsappMobileNumber"
//   onChange={handleChange}
//   placeholder="Whatsapp Mobile No."
//   value={data.whatsappMobileNumber}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="clinicOrCollegeAddress"
//   onChange={handleChange}
//   placeholder="Clinic/College Address"
//   value={data.clinicOrCollegeAddress}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="city"
//   onChange={handleChange}
//   placeholder="City"
//   value={data.city}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="district"
//   onChange={handleChange}
//   placeholder="District"
//   value={data.district}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="state"
//   onChange={handleChange}
//   placeholder="State"
//   value={data.state}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="pincode"
//   onChange={handleChange}
//   placeholder="Pincode"
//   value={data.pincode}
//   pattern="\d{5}"
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <select
//   name="gender"
//   onChange={handleChange}
//   required
//   value={data.gender}
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   disabled={true}
// >
//   <option value="">Select Gender</option>
//   <option value="M">Male</option>
//   <option value="F">Female</option>
//   <option value="O">Other</option>
// </select>
// <input
//   type="text"
//   name="paymentPageTitle"
//   onChange={handleChange}
//   placeholder="Payment Page Title"
//   value={data.paymentPageTitle}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="itemName"
//   onChange={handleChange}
//   placeholder="Item Name"
//   value={data.itemName}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="number"
//   name="itemAmount"
//   onChange={handleChange}
//   placeholder="Item Amount"
//   value={data.itemAmount}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="number"
//   name="itemQuantity"
//   onChange={handleChange}
//   placeholder="Item Quantity"
//   value={data.itemQuantity}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="number"
//   name="itemPaymentAmount"
//   onChange={handleChange}
//   placeholder="Item Payment Amount"
//   value={data.itemPaymentAmount}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="number"
//   name="totalPaymentAmount"
//   onChange={handleChange}
//   placeholder="Total Payment Amount"
//   value={data.totalPaymentAmount}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="paymentId"
//   onChange={handleChange}
//   placeholder="Payment ID"
//   value={data.paymentId}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="orderId"
//   onChange={handleChange}
//   placeholder="Order ID"
//   value={data.orderId}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="paymentDate"
//   onChange={handleChange}
//   placeholder="Payment Date"
//   value={data.paymentDate}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="currency"
//   onChange={handleChange}
//   placeholder="Currency"
//   value={data.currency}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
// <input
//   type="text"
//   name="paymentStatus"
//   onChange={handleChange}
//   placeholder="Payment Status"
//   value={data.paymentStatus}
//   required
//   className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//   readOnly={true}
// />
//                 </form>
//                 <div
//                 className={`"w-full p-8 ${getColor()} flex flex-col items-center justify-center text-white md:w-1/2`}
//               >
//                 {alreadyMarked ? (
//                   <>
//                     <h1 className="mb-5 text-3xl">Already Marked</h1>
//                   </>
//                 ) : (
//                   <>Verify</>
//                 )}
//               </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </div>
//       )}
//     </div>
//   );
// };

// export default AdminDashboard;

import { useState, useEffect } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Footer from "../Footer";

// import { Link } from "react-router-dom";
const AdminDashboard = () => {
  // Extract the payment ID and order ID from the query parameters
  const [already_marked, setAlreadyMarked] = useState(false);

  const [data, setData] = useState({
    paymentPageId: "",
    paymentPageTitle: "",
    paymentDate: "",
    orderId: "",
    itemName: "",
    itemAmount: 0,
    itemQuantity: 0,
    itemPaymentAmount: 0,
    totalPaymentAmount: 0,
    currency: "",
    paymentStatus: "",
    paymentId: "",
    fullName: "",
    email: "",
    whatsappMobileNumber: "",
    clinicOrCollegeAddress: "",
    state: "",
    district: "",
    city: "",
    pincode: "",
    gender: "",
    residence: "",
    lastValidationDate:"",
    isMark: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getColor = () => {
    if (already_marked) {
      return "bg-red-500";
    } else {
      return "bg-custom";
    }
  };
  const location = useLocation();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const paymentId = params.get("paymentId");
        const orderId = params.get("orderId");

        if (paymentId && orderId) {
          const token = localStorage.getItem("admintoken");
          const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/getUser`;
          setIsLoading(true);
          const response = await axios.post(
            url,
            { OrderId: orderId, PaymentId: paymentId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status === 200 && response.data) {
            setData((prevState) => ({ ...prevState, ...response.data.user }));
            setAlreadyMarked(response.data.user.isMark);
          }
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Something went wrong");
        }
      }

      setIsLoading(false);
    };

    fetchUserDetails();
  }, [location]);

  const handleLogout = async () => {
    setIsLoading(true); // Set loading state to true before starting the API call

    const token = localStorage.getItem("admintoken");
    const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/logout`;

    try {
      const response = await axios.post(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        localStorage.removeItem("admintoken");
        window.location = "/login";
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }

    setIsLoading(false);

    // history.push("/login");
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {try {
    const params = new URLSearchParams(location.search);
    const paymentId = params.get("paymentId");
    const orderId = params.get("orderId");

    if (paymentId && orderId) {
      const token = localStorage.getItem("admintoken");
      const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/verify`;
      setIsLoading(true);
      const response = await axios.post(
        url,
        { OrderId: orderId, PaymentId: paymentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setData((prevState) => ({ ...prevState, ...response.data.user }));
        setAlreadyMarked(response.data.user.isMark);
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500
    ) {
      toast.error(error.response.data.error);
    } else {
      toast.error("Something went wrong");
    }
  }

  setIsLoading(false);};

  return (
    <div>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <ClipLoader
            color={"#123abc"}
            loading={isLoading}
            css={`
              width: 150px;
              height: 150px;
            `}
            size={150}
          />
        </div>
      ) : (
        <div className="bg-gray-100">
          <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
            <h1 className="text-sm font-bold md:text-lg text-white ml-5">
              Shri Vishwa Vyakhyan Mala 2024
            </h1>

            <button
              className="border-none outline-none py-2 md:py-3 bg-white rounded-full w-24 md:w-28 font-bold text-xs md:text-sm cursor-pointer mr-5"
              onClick={handleLogout}
            >
              Logout
            </button>
          </nav>
          <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center p-8">
            <div className="w-11/12 md:max-w-4xl mx-auto shadow-md overflow-hidden md:flex rounded-2xl">
              <div className="w-full p-8 bg-white flex flex-col items-center justify-center md:w-1/2">
                <form onSubmit={handleSubmit}>
                  <h1 className="mb-5 text-3xl">
                    Details for Vishwavyakhan Mala
                  </h1>
                  <div className="mb-3">
                    <label
                      htmlFor="fullName"
                      className="block text-gray-700 mb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      onChange={handleChange}
                      placeholder="Full Name"
                      value={data.fullName}
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="block text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="whatsappMobileNumber"
                      className="block text-gray-700 mb-1"
                    >
                      Whatsapp Mobile No.
                    </label>
                    <input
                      type="tel"
                      id="whatsappMobileNumber"
                      name="whatsappMobileNumber"
                      onChange={handleChange}
                      placeholder="Whatsapp Mobile No."
                      value={data.whatsappMobileNumber}
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="clinicOrCollegeAddress"
                      className="block text-gray-700 mb-1"
                    >
                      Clinic/College Address
                    </label>
                    <input
                      type="text"
                      id="clinicOrCollegeAddress"
                      name="clinicOrCollegeAddress"
                      onChange={handleChange}
                      placeholder="Clinic/College Address"
                      value={data.clinicOrCollegeAddress}
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="city" className="block text-gray-700 mb-1">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      placeholder="City"
                      value={data.city}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="district"
                      className="block text-gray-700 mb-1"
                    >
                      District
                    </label>
                    <input
                      type="text"
                      name="district"
                      onChange={handleChange}
                      placeholder="District"
                      value={data.district}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="state" className="block text-gray-700 mb-1">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      onChange={handleChange}
                      placeholder="State"
                      value={data.state}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="pincode"
                      className="block text-gray-700 mb-1"
                    >
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      onChange={handleChange}
                      placeholder="Pincode"
                      value={data.pincode}
                      pattern="\d{5}"
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="gender"
                      className="block text-gray-700 mb-1"
                    >
                      Gender
                    </label>
                    <select
                      name="gender"
                      onChange={handleChange}
                      required
                      value={data.gender}
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      disabled={true}
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="O">Other</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="itemName"
                      className="block text-gray-700 mb-1"
                    >
                      Registration Type
                    </label>
                    <input
                      type="text"
                      name="itemName"
                      onChange={handleChange}
                      placeholder="Item Name"
                      value={data.itemName}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="totalPaymentAmount"
                      className="block text-gray-700 mb-1"
                    >
                      Total Payment Amount
                    </label>
                    <input
                      type="number"
                      name="totalPaymentAmount"
                      onChange={handleChange}
                      placeholder="Total Payment Amount"
                      value={data.totalPaymentAmount}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="paymentId"
                      className="block text-gray-700 mb-1"
                    >
                      Payment Id
                    </label>
                    <input
                      type="text"
                      name="paymentId"
                      onChange={handleChange}
                      placeholder="Payment ID"
                      value={data.paymentId}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="orderId"
                      className="block text-gray-700 mb-1"
                    >
                      Order Id
                    </label>
                    <input
                      type="text"
                      name="orderId"
                      onChange={handleChange}
                      placeholder="Order ID"
                      value={data.orderId}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="paymentDate"
                      className="block text-gray-700 mb-1"
                    >
                      Payment Date
                    </label>
                    <input
                      type="text"
                      name="paymentDate"
                      onChange={handleChange}
                      placeholder="Payment Date"
                      value={new Date(data.paymentDate)}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="paymentStatus"
                      className="block text-gray-700 mb-1"
                    >
                      Payment Status
                    </label>
                    <input
                      type="text"
                      name="paymentStatus"
                      onChange={handleChange}
                      placeholder="Payment Status"
                      value={data.paymentStatus}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="currency"
                      className="block text-gray-700 mb-1"
                    >
                      Currency
                    </label>
                    <input
                      type="text"
                      id="currency"
                      name="currency"
                      onChange={handleChange}
                      placeholder="Currency"
                      value={data.currency}
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="Last Scan"
                      className="block text-gray-700 mb-1"
                    >
                      Last Scan
                    </label>
                    <input
                      type="text"
                      id="Last Scan"
                      name="Last Scan"
                      onChange={handleChange}
                      placeholder="Currency"
                      value={new Date(data.lastValidationDate+'Z')}
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                      readOnly={true}
                    />
                  </div>
                  {already_marked ? (
                  <>
                    
                  </>
                ) : (
                  <><button
                  type="submit"
                  className="w-full px-4 py-2 rounded-lg bg-custom text-white"
                >
                  Verify
                </button></>
                )}
                  
                </form>
              </div>
              <div
                className={`"w-full p-8 ${getColor()} flex flex-col items-center justify-center text-white md:w-1/2`}
              >
                {already_marked ? (
                  <>
                    <h1 className="mb-5 text-3xl">Already Marked</h1>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;

// // import { useState } from "react";
// // import axios from "axios";

// // const Register = () => {
// //     const [data, setData] = useState({
// //         // ... your form fields
// //     });
// //     const [isSubmitted, setIsSubmitted] = useState(false);
// //     const [paymentValue, setPaymentValue] = useState(null);

// //     const handleChange = (e) => {
// //         setData({ ...data, [e.target.name]: e.target.value });
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         try {
// //             const url = `${process.env.REACT_APP_PLATFORM_URL}/user/register-event`;
// //             const response = await axios.post(url, data);
// //             setPaymentValue(response.data.paymentValue); // assuming the payment value is in the response
// //             setIsSubmitted(true);
// //         } catch (error) {
// //             console.error(error);
// //         }
// //     };

// //     const handleEdit = () => {
// //         setIsSubmitted(false);
// //     };

// //     return (
// //         <form onSubmit={handleSubmit} className="flex flex-col">
// //             {isSubmitted ? (
// //                 <>
// //                     <p>Payment Value: {paymentValue}</p>
// //                     <button type="button" onClick={handleEdit}>Edit Form</button>
// //                     <button type="button">Make Payment</button>
// //                 </>
// //             ) : (
// //                 <>
// //                     {/* Your form fields here */}
// //                     <button type="submit">Register</button>
// //                 </>
// //             )}
// //         </form>
// //     );
// // };

// // export default Register;
