// import React, { useEffect } from 'react';
// import Footer from "../Footer";

// const Main = () => {
//   // const history = useHistory();

//   const speakers = [
//     {
//       name: "Vaidya M. R. Vasudevan Nampoothiri",
//       topic: "Rasayana Chikistsa- Some Clinical Experiences",
//     },
//     {
//       name: "Vaidya. Shri Krishna Sharma Khandel",
//       topic: "Manorog in Ayurved: Concept to Clinic",
//     },
//     {
//       name: "Vd Samir Jamdagni",
//       topic: "Scope of Ayurveda in the Management of Cancer",
//     },
//     {
//       name: "Vaidya Mamatha K.V.",
//       topic: "Ayurvedic Management of PCOD",
//     },
//     {
//       name: "Vaidya Sreekanth P Namboothiri",
//       topic: "Management of Diabetic Retinopathy and Other Ocular Pathologies Through Ayurveda: Insights from Clinical Practice",
//     },
//     {
//       name: "Vaidya M.Gopikrishna",
//       topic: "Pottali and Sindoor Kalpana in management of Critical conditions: Clinical Experiences",
//     },
//     {
//       name: "Vaidya Mrityunjay Sharma",
//       topic: "Comprehensive approach for ayurvedic management of Spine Disorders",
//     },
//     {
//       name: "Vaidya Mahesh Birla",
//       topic: "Panchakarma Protocols for Successful Ayurvedic Practise",
//     },
//     {
//       name: "Vd Chandrakumar Deshmukh",
//       topic: "Vidhhakarma - As it is",
//     },
//     {
//       name: "Vd.Prashant Daundkar Patil.",
//       topic: "Ayurved Hospital Registration & Cashless Mediclaim Empanelment",
//     },
//     {
//       name: "Dr. Dhananjay Mhasade",
//       topic: "Legal Boundaries of Ayurved Practice and NABH in AYUSH",
//     },
//   ];

//   useEffect(() => {
//     const script = document.createElement('script');

//     script.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js';
//     script.id = 'razorpay-embed-btn-js';
//     script.defer = true;

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     }
//   }, []);

//   return (

//         <div>
//           <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
//             <h1 className="text-sm font-bold md:text-lg text-white ml-5">
//             Shree Vishwa Vyakhyanmala Nagpur 24
//             </h1>

//           </nav>
//           <section className="p-4">
//             <h2 className="font-bold text-xl mb-2">
//               Welcome
//             </h2>
//             <div className="flex justify-end">
//             <div class="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_OEPYGlWVyGkQvZ/view" data-text="Register" data-color="#528FF0" data-size="medium">
// </div>

//             </div>
//             <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
//               <h2 className="font-bold text-xl mb-2">
//                 Three days National Seminar On Ayurveda
//               </h2>

//               <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>

//                 <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
//                   <h2 className="font-bold text-xl mb-2">Event Details</h2>
//                   <p>Revisiting Siddhantas - Exploring Practices</p>
//                   <p>🗓️ Date : 20, 21 & 22 September 2024</p>
//                   <p>⏰ Time : 8 am to 6pm</p>
//                   <p>
//                     📍Venue : Kavivarya Suresh Bhat Sabhagruh, Great Nag Road,
//                     Reshim baug, Nagpur.
//                   </p>
//                 </div>
//                 <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
//                   <h2 className="font-bold text-xl mb-2">
//                     🔰 Registration charges 🔰
//                   </h2>
//                   <p>
//                     (Includes: 1 Welcome Kit + Seminar Entry + Breakfast + Lunch
//                     + High Tea for 3 days)
//                   </p>
//                   <p>▪️UG students & Interns : 1200/-</p>
//                   <p>▪️PG students and Observer : ₹ 1500/-</p>
//                   <p>▪️PhD Scholars/MD/Teachers/Practitioners/Paramedical : ₹1800/-</p>
//                   <p>
//                     ▪️ Conference with Presentation (Paper/poster) : 2000/-
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <section className="p-4">
//             <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
//               <h2 className="font-bold text-2xl mb-4">Speakers</h2>
//               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//                 {speakers.map((speaker, index) => (
//                   <div
//                     key={index}
//                     className="rounded overflow-hidden shadow-lg p-4 bg-white"
//                   >
//                     <div className="font-bold text-xl mb-2">{speaker.name}</div>
//                     <p className="text-gray-700 text-base">{speaker.topic}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </section>
//           <div>
//           </div>
//           <Footer/>
//         </div>

//   );
// };

// export default Main;
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const Main = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const speakers = [
    {
      name: "Vaidya M. R. Vasudevan Namboothiri",
      topic: "Rasayana Chikistsa- Some Clinical Experiences",
    },
    {
      name: "Vaidya Shri Krishna Sharma Khandel",
      topic: "Manorog in Ayurved: Concept to Clinic",
    },
    {
      name: "Vaidya Samir Jamdagni",
      topic: "Scope of Ayurveda in the Management of Cancer",
    },
    {
      name: "Vaidya Mamatha K.V.",
      topic: "Ayurvedic Management of PCOD",
    },
    {
      name: "Vaidya Sreekanth P Namboothiri",
      topic:
        "Management of Diabetic Retinopathy and Other Ocular Pathologies Through Ayurveda: Insights from Clinical Practice",
    },
    {
      name: "Vaidya M.Gopikrishna",
      topic:
        "Pottali and Sindoor Kalpana in management of Critical conditions: Clinical Experiences",
    },
    {
      name: "Vaidya Mrityunjay Sharma",
      topic:
        "Comprehensive approach for ayurvedic management of Spine Disorders",
    },
    {
      name: "Vaidya Mahesh Birla",
      topic: "Panchakarma Protocols for Successful Ayurvedic Practise",
    },
    {
      name: "Vaidya Chandrakumar Deshmukh",
      topic: "Vidhhakarma - As it is",
    },
    {
      name: "Vaidya Prashant Daundkar Patil.",
      topic: "Ayurved Hospital Registration & Cashless Mediclaim Empanelment",
    },
    {
      name: "Vaidya Dhananjay Mhasade",
      topic: "Legal Boundaries of Ayurved Practice and NABH in AYUSH",
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
    script.id = "razorpay-embed-btn-js";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <div>
          <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
          <div className="flex flex-row w-full items-center">
          <img src="v1.png" alt="Logo" className="h-12 w-auto" />
        <h1 className="text-sm font-bold md:text-lg text-white ml-5">
          Shree Vishwa Vyakhyanmala Nagpur 24
        </h1>
          </div>
        <div className="flex justify-end">
        <button
                  className="border-none outline-none py-2 md:py-3 bg-white rounded-full w-24 md:w-28 font-bold text-xs md:text-sm cursor-pointer mr-5"
                  onClick={() => navigate("/getqr")}
                >
                  Get QR code
                </button>
            </div>
      </nav>
      <section className="p-4">
        <h2 className="font-bold text-xl mb-2">Welcome</h2>
        <div className="flex justify-end">
          <div
            class="razorpay-embed-btn"
            data-url="https://pages.razorpay.com/pl_OEPYGlWVyGkQvZ/view"
            data-text="Register"
            data-color="#528FF0"
            data-size="medium"
          ></div>
        </div>
        <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
          <h2 className="font-bold text-xl mb-2">
            Three days National Ayurveda Conference
          </h2>

          <div className={`grid grid-cols-1 md:grid-cols-3 gap-4`}>
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">Event Details</h2>
              <p>Revisiting Siddhantas - Exploring Practices</p>
              <p>🗓️ Date : 20, 21 & 22 September 2024</p>
              <p>⏰ Time : 8 am to 6pm</p>
              <p>
                📍Venue : Kavivarya Suresh Bhat Sabhagruh, Great Nag Road,
                Reshim baug, Nagpur.
              </p>
            </div>
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
                📊 Call for Paper and Poster Presentation
              </h2>
              <p className="mb-2">
                Poster and paper presenters are expected to mail Abstract in 300
                words & key words before - 31st August 2024 on Email ID
                vishwapapers9@gmail.com.
              </p>
              <p className="font-bold text-l mb-2">📖📚 Theme/Topic</p>
              Although There is no specific theme, Ayurvedic thought is expected
              to be at the forefront of the work.
              <p>👉 For more information please contact</p>
              <p>Vd Prasad Pandkar : 9881907552</p>
              <p>Vd Girish Shirke : 9422367280</p>
            </div>
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
                🔰 Registration charges 🔰
              </h2>
              <p>
                (Includes: 1 Welcome Kit + Seminar Entry + Breakfast + Lunch +
                High Tea for 3 days)
              </p>
              <p>▪️UG students & Interns : 1500/-</p>
              <p>▪️PG students & Observer : ₹ 1800/-</p>
              <p>▪️Practitioner/faculty/Md/PhD/RMO : ₹2100/-</p>
            </div>
          </div>
        </div>
      </section>
      <section className="p-4">
        <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
          <h2 className="font-bold text-2xl mb-4">Speakers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="rounded overflow-hidden shadow-lg p-4 bg-white"
              >
                <div className="font-bold text-xl mb-2">{speaker.name}</div>
                <p className="text-gray-700 text-base">{speaker.topic}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-4">
        <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
          <h2 className="font-bold text-2xl mb-4">Helpline Numbers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="font-bold text-xl mb-2">Male Helpline Numbers</h3>
              <ul>
                <li>1. Vd. Yogi Tajane - 8888461426</li>
                <li>2. Vd. Nishant Dahake - 9975797624</li>
                <li>3. Vd. Shubham Sakhare - 8407976204</li>
                <li>4. Vd. Prajwal Kothurkar - 8408070688</li>
                <li>5. Vd. Abhishek Yadav - 8169407953</li>
                <li>6. Vd. Shubham Dhoot - 8975656999</li>
                <li>7. Vd. Yogesh Misal - 8208378076</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-2">
                Female Helpline Numbers
              </h3>
              <ul>
                <li>1. Vd. Shraddha Dubey - 8390888599</li>
                <li>2. Vd. Pratiksha Paitod - 8275393148</li>
                <li>3. Vd. Ruchita Ughade - 7304014948</li>
                <li>4. Vd. Rupali Anjankar - 9975542547</li>
                <li>5. Vd. Amruta Amle - 9850322601</li>
                <li>6. Vd. Unnati Sonkar - 9552539671</li>
                <li>7. Vd. Ruchika Waghmare - 7066586639</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div></div>
      <Footer />
    </div>
  );
};

export default Main;
