// import { useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import ClipLoader from "react-spinners/ClipLoader";
// import Footer from "../Footer";

// const Login = () => {
//   const [data, setData] = useState({ email: "", password: "" });
//   const [isLoading, setIsLoading] = useState(false);

//   const navigate = useNavigate();

//   const handleChange = ({ currentTarget: input }) => {
//     setData({ ...data, [input.name]: input.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     try {
//       const url = `${process.env.REACT_APP_PLATFORM_URL}/user/login`;
//       console.log(url);
//       const res = await axios.post(url, data);
//       console.log(res.data);
//       if (res.status === 200) {
//         localStorage.setItem("token", res.data.token);
//         window.location = "/";
//       } else {
//         toast.error(res.error);
//       }
//     } catch (error) {
//       console.log(error);
//       if (
//         error.response &&
//         error.response.status >= 400 &&
//         error.response.status <= 500
//       ) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went wrong");
//       }
//     }
//     setIsLoading(false);
//   };

//   return (
//     <div>
//       {isLoading ? (
//         <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
//           <ClipLoader
//             color={"#123abc"}
//             loading={isLoading}
//             css={`
//               width: 150px;
//               height: 150px;
//             `}
//             size={150}
//           />
//         </div>
//       ) : (
//         <div>
//           <div className="bg-gray-100">
//             <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
//               <h1 className="text-sm font-bold md:text-lg text-white ml-5">
//                 Shri Vishwa Vyakhyan Mala 2024
//               </h1>
//             </nav>
//             <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center">
//               <div className="w-11/12 md:max-w-xl mx-auto shadow-md overflow-hidden md:flex rounded-2xl">
//                 <div className="w-full p-8 bg-white flex flex-col items-center justify-center md:w-1/2">
//                   <form onSubmit={handleSubmit}>
//                     <h1 className="mb-5 text-3xl">Login to Your Account</h1>
//                     <input
//                       type="email"
//                       placeholder="Email"
//                       name="email"
//                       onChange={handleChange}
//                       value={data.email}
//                       required
//                       className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//                     />
//                     <input
//                       type="password"
//                       placeholder="Password"
//                       name="password"
//                       onChange={handleChange}
//                       value={data.password}
//                       required
//                       className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
//                     />
//                     <button
//                       type="button"
//                       className="w-full px-4 py-2 rounded-lg bg-white text-blue-500"
//                       onClick={() => navigate("/forgot-password")}
//                     >
//                       Forgot Password
//                     </button>
//                     <button
//                       type="submit"
//                       className="w-full px-4 py-2 rounded-lg bg-custom text-white"
//                     >
//                       Sign In
//                     </button>
//                   </form>
//                 </div>
//                 <div className="w-full p-8 bg-custom flex flex-col items-center justify-center text-white md:w-1/2">
//                   <h1 className="mb-5 text-3xl">New Here ?</h1>
//                   <Link to="/signup">
//                     <button
//                       type="button"
//                       className="w-full px-4 py-2 rounded-lg bg-white text-blue-800"
//                     >
//                       Sign Up
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <Footer/>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Login;
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../Footer";
import QRCode from "qrcode.react";

const Login = () => {
  const [data, setData] = useState({ email: "", whatsappMobileNumber: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [FullName, setFullName] = useState("");
  const [OrderId, setOrderId] = useState("");
  const [PaymentId, setPaymentId] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_PLATFORM_URL}/api/User`;
      // console.log(url);
      const res = await axios.post(url, data);
      console.log(res.data);
      if (res.status === 200) {
        const { orderId, paymentId, fullName } = res.data;
        setFullName(fullName);
        setOrderId(orderId);
        setPaymentId(paymentId);
        // localStorage.setItem("token", res.data.token);
        // window.location = "/";
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
    setIsLoading(false);
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "vishwa.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <ClipLoader
            color={"#123abc"}
            loading={isLoading}
            css={`
              width: 150px;
              height: 150px;
            `}
            size={150}
          />
        </div>
      ) : (
        <div>
          <div className="bg-gray-100">
            <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full p-2  shadow-md items-center">
              <img src="v1.png" alt="Logo" className="h-12 w-auto" />
              <h1 className="text-sm font-bold md:text-lg text-white ml-5">
                Shree Vishwa Vyakhyanmala Nagpur 24
              </h1>
            </nav>
            <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center">
              <div className="w-11/12 md:max-w-xl mx-auto shadow-md overflow-hidden md:flex rounded-2xl">
                <div className="w-full p-8 bg-white flex flex-col items-center justify-center md:w-1/2">
                  <form onSubmit={handleSubmit}>
                    <h1 className="mb-5 text-3xl">Get QR Code</h1>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      value={data.email}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Whatsapp Mobile Number"
                      name="whatsappMobileNumber"
                      onChange={handleChange}
                      value={data.whatsappMobileNumber}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                    />
                    {/* <button
                      type="button"
                      className="w-full px-4 py-2 rounded-lg bg-white text-blue-500"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password
                    </button> */}
                    <button
                      type="submit"
                      className="w-full px-4 py-2 rounded-lg bg-custom text-white"
                    >
                      Get QR Code
                    </button>
                  </form>
                </div>
                <div className="w-full p-8 bg-custom flex flex-col items-center justify-center text-white md:w-1/2">
                  {OrderId && PaymentId ? (
                    <>
                      <h1 className="mb-5 text-3xl">{FullName}</h1>
                      
                        <QRCode
                          id="qrcode"
                          value={`${process.env.REACT_APP_WEB_URL}/vishwa-dashboard?orderId=${OrderId}&paymentId=${PaymentId}`}
                          size={256}
                          includeMargin={true}
                        />
<br /><div className="rounded overflow-hidden shadow-lg p-4 bg-white text-black">
                        <button onClick={downloadQRCode} >
                          Download QR Code
                        </button>
                      </div>
                    </>
                  ) : (
                    <h1 className="mb-5 text-3xl">QR code</h1>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
