import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../Footer";

const AdminLogin = () => {
  const [data, setData] = useState({ username: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_PLATFORM_URL}/api/Admin/login`;
      console.log(url);
      const res = await axios.post(url, data);
      console.log(res.data);
      if (res.status === 200) {
        localStorage.setItem("admintoken", res.data.token);
        window.location = "/vishwa-dashboard";
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <ClipLoader
            color={"#123abc"}
            loading={isLoading}
            css={`
              width: 150px;
              height: 150px;
            `}
            size={150}
          />
        </div>
      ) : (
        <div>
          <div className="bg-gray-100">
            <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full p-2  shadow-md items-center">
              <img src="v1.png" alt="Logo" className="h-12 w-auto" />
              <h1 className="text-sm font-bold md:text-lg text-white ml-5">
                Shree Vishwa Vyakhyanmala Nagpur 24
              </h1>
            </nav>
            <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center">
              <div className="w-11/12 md:max-w-xl mx-auto shadow-md overflow-hidden md:flex rounded-2xl">
                <div className="w-full p-8 bg-white flex flex-col items-center justify-center md:w-1/2">
                  <form onSubmit={handleSubmit}>
                    <h1 className="mb-5 text-3xl">Login to Your Account</h1>
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      onChange={handleChange}
                      value={data.username}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      value={data.password}
                      required
                      className="w-full px-4 py-2 mb-3 border rounded-lg focus:outline-none"
                    />
                    <button
                      type="submit"
                      className="w-full px-4 py-2 rounded-lg bg-custom text-white"
                    >
                      Sign In
                    </button>
                  </form>
                </div>
                <div className="w-full p-8 bg-custom flex flex-col items-center justify-center text-white md:w-1/2">
                  <h1 className="mb-5 text-3xl">Admin Login</h1>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
